import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormFileUploadField from 'components/ui/form/FormFileUploadField';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string()
    // .min(4, { label: 'block.imageAddEditModal.validation.nameMin', value: 4 })
    .required('settings.targets.validation.name'),
  file: yup.mixed().nullable(),
});

const BlockAddEditImageModal = (props) => {
  const { open, setOpen, data, refetchFn = () => {} } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      name: '',
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submit(
        { type: 'post', contentType: 'formData' },
        values,
        `/admin/contract/addfile/${data?.id}`,
        null,
        null,
        true,
        handleFinish
      );
    },
  });

  const handleFinish = () => {
    refetchFn();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{
        className: '!base-border !shadow-none',
      }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!text-center !mb-5 !bg-[#CDCDCDC7]'
      >
        <span>{t('common.fields.uploadFile')}</span>
      </DialogTitle>

      <DialogContent>
        <div className='py-3 max-w-[700px]'>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} sm={6} xs={12}>
                <div className='-mb-1 text-gray-600 text-sm'>
                  {t('common.fields.simpleName')}
                </div>
                <FormTextField
                  delay={0.001}
                  placeholder={t('common.fields.simpleName')}
                  fieldName='name'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} sm={6} xs={12}>
                <div className='-mb-1 text-gray-600 text-sm'>
                  {t('common.fields.uploadFile')}
                </div>
                <FormFileUploadField
                  delay={0.0013}
                  accept='.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .avif, .HEIC'
                  fieldName='file'
                  formik={formik}
                  label={t('common.fields.uploadFile')}
                  btnLabel={t('common.fields.uploadFile')}
                  fileSize={1}
                />
              </Grid>

              <Grid item={true} sm={12} xs={12}>
                <div className='!justify-end !w-full !flex'>
                  <FormActionButtons
                    delay={0.4}
                    isSubmitting={isSubmitting}
                    formType='dialog'
                    setOpen={setOpen}
                    reset={formik.resetForm}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(BlockAddEditImageModal);
